<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { PlayersActivityGame } from "@/types";

const { t } = useT();
const { open } = useNlcModals();
const { handleOpenGame } = useOpenGame(open);
const loginGuard = useLoginGuard();
const { activeGameMode } = useSwitchMode();

const changeSlideDisabled = ref(false);
const sliderRef = ref();
const sliderActiveSlide = ref(0);
const isLast = ref(false);

const { playersActivity, fetchedPlayersActivity } = useLatestWinners();
const documentVisibleState = useDocumentVisibility();

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto" },
	created(slider) {
		sliderActiveSlide.value = slider.track.details?.abs || 0;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details?.abs || 0;
	},
	detailsChanged(slider) {
		isLast.value =
			slider.track.details?.slides?.[playersActivity.value.length - 1]?.portion >= 0.9 ||
			!slider.track.details?.slides.length;
	}
};

const updateAfterAnimation = debounce(() => {
	sliderRef.value?.slider?.update();
	changeSlideDisabled.value = false;
}, 900);

const updateSlider = () => {
	changeSlideDisabled.value = true;
	updateAfterAnimation();
};

const openGame = (game: PlayersActivityGame) => {
	loginGuard({
		success: () => {
			if (!game?.slug) {
				return;
			}
			if (game?.gameExclusiveData?.isExclusive && activeGameMode.value === "coins") {
				open("LazyOModalGameExclusive", { gameId: game.id });
				return;
			}
			if (game.isOnlyForApp) {
				dispatchQuasarEvent("quasar:openLockedGame", { detail: game });
			} else {
				handleOpenGame(game?.slug);
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const slidesLength = computed(() => sliderRef.value?.slider?.slides.length ?? 0);

const prevSlide = () => {
	if (slidesLength.value > 0 && !changeSlideDisabled.value) {
		sliderRef.value?.slider?.prev();
	}
};

const nextSlide = () => {
	if ((slidesLength.value > 0 || !isLast.value) && !changeSlideDisabled.value) {
		sliderRef.value?.slider?.next();
	}
};

watch(
	() => playersActivity,
	() => updateSlider(),
	{ deep: true }
);
</script>
<template>
	<div :class="['wrapper', { 'disable-drag': changeSlideDisabled }]">
		<MHomeSectionHeader
			class="header-slider"
			:title="t('Latest Winners')"
			icon="24/tournaments"
			data-tid="last-winners"
			showNavButtons
			:sliderActiveSlide="sliderActiveSlide !== 0"
			:isLast="isLast"
			@prevSlide="prevSlide"
			@nextSlide="nextSlide"
		/>

		<ClientOnly>
			<ASlider v-if="documentVisibleState === 'hidden'" ref="sliderRef" :options="sliderOptions">
				<TransitionGroup :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
					<div
						v-for="(element, index) in fetchedPlayersActivity"
						:key="element.id"
						class="card-wrapper keen-slider__slide"
					>
						<div class="card">
							<MLatestWinnersCard
								class="card-item"
								:data-tid="`last-winners-card-${index}`"
								:item="element"
								@openGame="openGame"
							/>
						</div>
					</div>
				</TransitionGroup>
			</ASlider>
			<ASlider v-if="documentVisibleState === 'visible'" ref="sliderRef" :options="sliderOptions">
				<TransitionGroup :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
					<div v-for="(element, index) in playersActivity" :key="element.id" class="card-wrapper keen-slider__slide">
						<div class="card">
							<MLatestWinnersCard
								class="card-item"
								:data-tid="`last-winners-card-${index}`"
								:item="element"
								@openGame="openGame"
							/>
						</div>
					</div>
				</TransitionGroup>
			</ASlider>
		</ClientOnly>
	</div>
</template>

<style scoped lang="scss">
.disable-drag {
	pointer-events: none;
}

.flip-list-move {
	transition: transform 0.8s ease;
}

.list-transform-move {
	transition: transform 0.8s;
}

.list-width-enter-active,
.list-width-leave-active {
	transition:
		min-width 0.8s,
		width 0.8s,
		opacity 0.8s;
}
.list-width-enter-from,
.list-width-leave-to {
	opacity: 0;
	width: 0 !important;
	min-width: 0 !important;
	max-width: 0 !important;
}

.card-wrapper {
	&:first-child {
		margin-left: gutter(-0.5);
	}
	&:last-child {
		margin-right: gutter(-0.5);
	}
}

.card {
	padding: 0 gutter(1);

	@include media-breakpoint-down(md) {
		padding: 0 gutter(0.75);
	}
}

.card-item {
	width: 100%;
}

.cards {
	margin-top: gutter(6);
	position: relative;
	z-index: 5;

	@include media-breakpoint-down(md) {
		width: calc(100% + 15px);
		margin-top: gutter(5);
	}
	&-container {
		display: flex;
		position: relative;
		padding-top: gutter(2.5);

		@include media-breakpoint-down(md) {
			overflow: visible;
		}
	}
}

.header-slider {
	margin-bottom: gutter(2.5);

	@include media-breakpoint-down(md) {
		margin-bottom: gutter(1.25);
	}
}

.wrapper {
	@include media-breakpoint-down(md) {
		overflow: hidden;
		padding-left: gutter(2);

		&:deep(.keen-slider) {
			overflow: visible;
		}
	}
}
</style>
